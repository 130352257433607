'use client';

import ErrorPage from './error';

interface ErrorPageProps {
  error: Error;
  reset: () => void;
}

const GlobalError = ({ error, reset }: ErrorPageProps) => {
  return (
    <html lang="ko">
      <body>
        <ErrorPage error={error} reset={reset} />
      </body>
    </html>
  );
};

export default GlobalError;
